import axios from "axios";

const ENVIRONMENT = 'live'; // development || staging || live
let API_ENDPOINT = '';

switch (ENVIRONMENT) {
    case 'development':
        API_ENDPOINT = 'http://localhost/tellavision/?rest_route=/'; // development is not full working at the moment (it is not retreive by pages).
        break;
    case 'staging':
        API_ENDPOINT = 'https://adminstaging.tellavision.agency/wp-json/';
        break;
    case 'live':
        API_ENDPOINT = 'https://admin.tellavision.agency/wp-json/';
        break;
    default:
        break;
}
// const API_ENDPOINT = "http://192.168.0.158/tellavision/wp-json/";
// const API_ENDPOINT = "https://tellvisionagency.aliansoftware.net/wp-json/";
const API = {
    fetchLogo: function () {
        return axios.get(API_ENDPOINT + `mytheme/v1/settings/`);
    },
    fetchHomeContent: function () {
        return axios.get(API_ENDPOINT + "wp/v2/pages/23/");
    },
    fetchFeaturedImageUrl: function (id) {
        return axios.get(API_ENDPOINT + "wp/v2/media/" + id);
    },
    fetchPrimaryMenu: function () {
        return axios.get(API_ENDPOINT + "tellavision/v1/main-data/primary-menu");
    },
    fetchAboutMenus: function () {
        return axios.get(API_ENDPOINT + "tellavision/v1/main-data/about-menu");
    },
    fetchMainData: function () {
        return axios.get(API_ENDPOINT + "tellavision/v1/main-data/");
    },
    fetchAboutMenus: function () {
        return axios.get(API_ENDPOINT + "menus/v1/locations/about-menu");
    },
    fetchMenuItemStyle: function () {
        return axios.get(API_ENDPOINT + "acf/v2/options/");
    },
    fetchAllArtists: function () {
        return axios.get(API_ENDPOINT + "tellavision/v1/artists/");
    },
    fetchMenuItemMainPageData: function (menuTitle) {
        return axios.get(API_ENDPOINT + "wp/v2/" + menuTitle + "/?per_page=50");
    },
    fetchMenuItemSubPageData: function (menuTitle, subMenuId) {
        return axios.get(API_ENDPOINT + "wp/v2/" + menuTitle + '/' + subMenuId);
    },
    fetchPageContent: function (pageId) {
        return axios.get(API_ENDPOINT + "wp/v2/pages/" + pageId);
    },
    fetchPasswords: function () {
        return axios.get(API_ENDPOINT + "wp/v2/passwords");
    }
};

export default API;
